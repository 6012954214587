.App {
  text-align: center;
}

.Text {
  color: rgb(72, 72, 72);
  font-family: "Lucida Console", Monaco, monospace;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: rgb(112, 153, 164);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* body {
  background-color: #181819;
} */

#content {
  font-family: 'Raleway', sans-serif;
  color: white;
  text-align: center;
  margin-top: 30px;
  font-size: 30px;
}
/* 
h1 {
  
  font-family: 'Raleway', sans-serif;
	color: #2FBDF4;

  
} */

h4 {
  
  font-family: 'Raleway', sans-serif;
	color: #2FBDF4;

  
}

// SVG Styles

#svgElement {
  
  width: 620px;
  margin-left: auto;
  margin-right: auto;

}



// SVG Animation



#ConeLight {
  
  fill: rgb(255, 165, 0) !important;
  animation: .6s infinite coneCycle;
}

@-webkit-keyframes coneCycle{
  
  50% {
    
    fill: white;
    
  }
  
  
  100% {
    
        fill: #d62209;

    
  }
  
}


// Crane Light Animation

#CraneLight {
  
  fill: white;
  animation: .6s infinite coneCycle;

}



// Crane Whinch Animation 


#Winch {
  transform: translateX(88px);
  animation: 4s linear infinite winchCycle;
  animation-direction: alternate-reverse;
}


@-webkit-keyframes winchCycle {
  
  0% {
    
        transform: translateX(88px);
  }
  
  100% {
    
        transform: translateX(4px);
    
  }
  
 
}


// Paint Roller Animation

#PaintRoller {
  
  animation: .7s ease infinite paintRoller;
  transform: translateY(-1px);
  animation-direction: alternate-reverse;

  
  
}


@-webkit-keyframes paintRoller {
  
  0% {
    
    transform: translateY(-7px);
    
  }
  
  
  100% {
    
     transform: translateY(21px);

    
  }
  
  
  
  
}



a {
  
  color: white;
  text-decoration: none;
  transition: ease .2s;
}

a:hover {
  
  color: #2FBDF4;
  
}
